.main {
    min-height: 30rem;
    padding: 4rem;
}

.tabs_container {
    display: flex;
    flex-direction: row;
    border: 1px solid #F85C70;
    padding: 3rem;
}

.tab_block{
    margin-right: 3rem;
}

.tabs {
    border: 1px solid rgba(248, 93, 112, 1);
    padding: 0.5rem;
    background:rgba(230, 146, 167, 0.61);
    cursor: pointer;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    width: 18rem;
}

.active_tab {
    border: 3px solid #F85C70;
    box-shadow: 0 0 10px #F85C70;
}

.content {
    display: none;
}

.tab_content a {
    border: 1px solid #F85C70;
    padding: 0.75rem 1rem;
    /* width: 20rem; */
    display: flex;
    justify-content: space-between;
}

@media(max-width: 972px){

    .main {
        padding: 0;
        min-height: 0;
    }
    .main h1 {
        font-size: 1.3rem;
    }
    .tabs_container {
       border: 0;
       padding: 0; 
       display: block;   
    }

    .tab_block {
        margin-right: 0;
        display: block;
        margin-bottom: 3rem;
    }

    .showOptions {
        display: block;
    }


    .hideOptions {
        display: none;
    }

    .tabs {
        width: 85%;
        margin: auto;
    }

    .tab_content {
        margin-block: 1rem 3rem;
    }

    .tab_content a{
        width: 85%;
        margin: auto;
        min-width: 0;
        box-shadow: 0 0 10px rgba(248, 92, 112, 1);
    }
}
