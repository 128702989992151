.carousel {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 2rem;
}

.image_container{
    position: relative;
    border: 5px solid rgba(248, 93, 112, .8);;
}

.carousel img {
    object-fit: cover;
    height: 17rem;
    width: 30rem;
}

.carousel img{
    display: none;
}

.showCurrent {
    display: block !important;
}

.message_seller {
    margin-block: 0 1rem;
}

.align_self_start {
    align-self: flex-start;
    margin-block: 1rem;
}

.message_seller_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 1.5rem;
}

.message_seller_input {
    padding: 0.5rem;
    margin-block:  0.3rem;
    width: 256px;
}

@media(max-width: 971px){
    .carousel img {
        width: 100%;
    }
}
