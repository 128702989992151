.messenger {
    height:  calc(88vh - 96px);
    display: flex;
    padding: 2rem;
}

.chat_menu {
    flex: 3;
}

.chat_box {
    flex: 9;
}

.chat_box_top {
    height: 65%;
    overflow-y: scroll;
}

.chat_box_bottom {
    display: flex;
    /* align-items: center; */
    margin-top: 2rem;
    /* justify-content: space-between; */
}

.chat_message_input {
    width: 80%;
    height: 5.625rem;
    padding: 0.625rem;
    font-size: 0.7rem;
    border-radius: 5px;
    border: 1px solid gray;
}

.chat_submit_button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: rgb(250, 146, 164);
    width: 6rem;
    height: 5.625rem;
}

.chat_menu_wrapper, .chat_box_wrapper {
    padding: 0.7rem;
}

.chat_menu {
    border-right: 1px solid rgb(209, 209, 209);
}

.chat_menu_wrapper h2{
    text-align: center; 
    margin-bottom: 1rem;
}

.chat_menu_wrapper, .chat_box_wrapper {
    height: 100%;
}

.none {
    display: none;
}

@media(max-width: 971px) {
    
    .chat_menu {
        border: none;
    }

    .center {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center ;
    }
}