.main {
    min-height: 30rem;
    padding: 4rem;
}

.tabs_container {
    display: flex;
    flex-direction: row;
    border: 1px solid salmon;
    padding: 3rem;
}

.tab_block{
    margin-right: 3rem;
}

.tabs {
    border: 1px solid rgba(248, 93, 112, 1);
    padding: 1rem 2rem;
    background:rgb(224, 178, 183);
    border-radius: 8px;
    cursor: pointer;
}

.active_tab {
    border: 3px solid rebeccapurple;
}

.content {
    display: none;
}

.tab_content a {
    display: block;
}