.conversations {
    cursor: pointer;
    padding: 0.5rem;
    margin-top: 1rem; 
    display: flex;
    align-items: center;   
}

.conversations:hover {
    background: rgba(230, 146, 167, 0.3);
}

.conversation_name {
    font-weight: 500;
}

.conversation_preview {
    font-size: 0.7rem;
}

.chat_profile {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 0.5rem;
    border: 5px dashed rgb(248, 92, 112);
    background: rgb(236, 214, 214);
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat_profile img {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
}