.message {
    display: flex;
    flex-direction: column;
}

.message_top {
    display: flex;
}

.message_text {
    padding: 0.625rem;
    border-radius: 20px;
    background: rgba(230, 146, 167, 1);
    max-width: 300px;
}

.message_bottom {
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
}

.message.own {
    align-items: flex-end;
}

.message.own .message_text {
    background: rgba(230, 146, 167, 1);
}

.no_conversations {
    
}