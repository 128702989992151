@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  overflow-x: hidden;
}

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 


body {
  font-family: Geomanist, Inter, "Inter";
}

.container {
  max-width: 1232px;
  margin: auto;
}

a {
  text-decoration: none;
  color: black;
  transition: all 150ms ease-in;
}


a:hover {
  color: #F85D70;
}

input:focus { 
  outline: none !important;
  border: 1px solid #F85D70;
  box-shadow: 0 0 10px #F85C70;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

textarea {
  resize: none;
  font-size: 0.7rem;
}

textarea:focus { 
  outline: none !important;
  border: 1px solid #F85D70;
  box-shadow: 0 0 10px #F85C70;
}

.search_block {
  height: 17.3125rem;
  background: rgba(240, 189, 201, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline: 2rem;
}

.search {
  width: 48.5rem;
  height: 3.5rem;
  padding-inline: 2rem;
  border: 2px solid #F1707F;
  box-shadow: inset 2px 2px 4px #F1707F;
  font-size: 1rem;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 26.5rem;
}

.search input {
  border: none;
  width: 100%;
}

.search input:focus {
  border: none;
  box-shadow: none;
}

.first_photo {
  position: relative;
  bottom: -47px;
  left: 17px;
}

.second_photo {
  position: relative;
  bottom: -15px;
  right: -15px;
}



.categories {
  text-align: center;
  margin-block: 2rem;
  color: rgba(248, 92, 112, 1);
  font-size: 2rem;
  font-weight: bold;
}

.category_buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-inline: 4rem;
  max-width: 50rem;
  margin: 0 auto;
}


.category_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-right: 1.75rem;
  margin-bottom: 2rem;
}

.category_button p{
  text-align: center;
  margin-top: 1rem;
}

.logo {
  cursor: pointer;
}

button {
  border: none;
  cursor: pointer;
}

.hide_for_desktop{
  display: none;
}


.button {
  background: rgba(248, 93, 112, 1);
  padding: 0.625rem 2.25rem;
  font-size: 0.875em;
  font-weight:bold;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(248, 93, 112, .8);
}

.header {
  height: 4rem;
  background: rgba(230, 146, 167, 0.61);
  border: 1px solid #F85D70;
  box-shadow: 3px 4px 4px #F85C70;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4.4375rem;
  margin-bottom: 0.2rem;
}

.header_buttons {
  display: flex;
  align-items: center;
}

.header_user {
  margin-right: 3.25rem;
}

.header_user a {
  font-size: 0.875em;
  font-weight: bold;
}

.header_sell {
  background: rgba(248, 93, 112, 1);
  padding: 0.625rem 2.25rem;
  font-size: 0.875em;
  font-weight:bold;
}

.profile_picture {
  height: 5.5rem;
  width: 5.5rem;
  border: 5px dashed rgb(248, 92, 112);
  background: rgb(236, 214, 214);
  border-radius: 50%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_picture img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
}

.profile_picture:hover {
  background: #f85c7195;
  border: 4px dashed #fb3d56;
}

.signup {
  margin-block: 2rem;
}

.signup_header {
  margin-block: 3rem;
  text-align: center;
  color: rgba(248, 92, 112, 1);
}

.signup_form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
}

.signup_form input{
  padding: 1rem 1rem;
  border-radius: 5px;
  border: 0.5px solid rgba(248, 93, 112, 1); 
  margin-bottom: 1.75rem;
}

.signup_form input[type="submit"]{
  padding-block: 1.5rem;
  font-weight: bold;
}

.signup_form label {
  margin-bottom: 0.7rem;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 1.5rem;
}

.filter_select {
  width: 15rem;
  padding: 0.5rem 1rem;
  height: 3rem;
}

.error {
  background-color: rgb(255, 71, 71);
  color: white;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.error p {
  text-align: center;
}

.success {
  background-color: rgb(15, 88, 0);
  color: white;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.success p {
  text-align: center;
}

.category_block {
  height: 17.3125rem;
  background: rgba(240, 189, 201, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.category_block {
  text-align: center;
}

.align_self_corrections {
  align-self: center !important;
  text-align: center;
}

.listings {
  display: flex;
  justify-content: center;
  margin-block: 2rem;  

}

.card {
  width: 15.375rem;
  min-height: 22rem;
  background: rgba(240, 189, 201, 1);
  border-radius: 10px;
  margin-bottom: 2rem;
  cursor: pointer;
}

.card:not(odd){
  margin: 0 1.47rem 1.46875rem;
}

.button_padding {
  padding: 1rem;
}

.upload {
  display: flex;
  flex-direction: column;
  width: 27rem;
  margin: auto;
  margin-bottom: 5rem;
}

.upload input, textarea, select {
  margin-bottom: 1rem;
}

.upload input {
padding: 0.5rem;
}

.upload select {
  padding: 0.5rem;
}

.upload label {
  margin-bottom: 0.5rem;
}

.upload textarea {
  padding: 0.5rem;
}

.upload_title {
  text-align: center;
  margin-block: 3rem;
  color: rgba(248, 92, 112, 1);
}

.file_select {
  height: 12rem;   
  max-width: 27rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dashed #F85C70;
  background: rgb(236, 214, 214);
  cursor: pointer;
  transition: all 180ms ease-in-out;
  width: 100%;
}

.file_select:hover {
  background: #f85c7195;
  border: 4px dashed #fb3d56;
}

.input_hide {
  display: none;
}

.upload button {
  margin-block: 2rem;
}

.upload textarea {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.listings_container {
  display: flex;
  align-items: flex-start;
  padding: 3rem;
  min-height: 21rem;
}

.listings_container select {
  padding: 0.25rem 0.5rem;
  width: 7rem;
}

.listings_container h5 {
  margin-bottom: 0.5rem;
}

.budget {
  display: flex;
  max-width: 7rem;
  margin-block: 0.7rem;
}

.budget input {
  width: 2.5rem;
  padding: 0.25rem;
}

.budget input:first-child {
  margin-right: 0.5rem; 
}

.product_filter label {
  display: block;
}

.price_filter button {
  padding: 0.25rem 0.5rem;
  background:rgb(248, 93, 112);
  margin-bottom: 1rem;
  width: 12rem;
}

.filter_input {
  width: 7rem;
  margin-block: 0.25rem 0.5rem;
  padding: 0.25rem;
}

.carousel_control {
  display: flex;
}

.carousel_control p:first-child {
  margin-right: 0.5rem;
}

.carousel_control p:hover {
  cursor: pointer;
  color: rgb(248, 93, 112);
}

.no_listings {
  margin-block: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_listings h2 {
  display: block;
  text-align: center;
}

.no_listings h3 {
  text-align: center;
}

.listing_page{
  height: 63%;
  max-width: 1000px;
  margin: auto;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listing_page img {
  object-fit: cover;
  width: 100%;
  height: 35.75rem;
}

.listing_page h1, h3 {
  align-self: start;
  margin-block: 0.5rem
}

.menu_buttons {
  display: flex;
  align-items: center;
}


.menu_buttons a, button:first-child {
  margin-right: 1rem;
}

.menu_open_buttons {
  position: relative;
  z-index: 999;
}


.menu_open_buttons button{
  /* margin-bottom: 1rem; */
}

.no_block {
  margin-block: 0 !important;
}

.no_block h3 {
  margin: 0 !important;
  padding: 0 !important;
}

.no_results {
  align-self: center;
}

.card {
  padding: 1.625rem 0.8125rem;
  display: flex;
  flex-direction: column;
}

.card h3, .card p {
  margin-bottom: 0.5rem;
}

.image_box {
  width: 90%;
  margin: auto;
}

.image_box img{
  width: 11.90875rem;
  height: 8.625rem;
  margin-bottom: 1.625rem;
  object-fit: cover;
}

.account {
  padding: 4rem;
}

.error_page {
  height: 63vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error_page h1 {
  margin-bottom: 1rem;
}

.sub_category_block {
  padding: 4rem 2rem;
}


.sub_category_block a {
  min-width: 0 !important;
  margin: auto;
  transition: all 100ms ease-in-out;
  max-width: 42rem;
}

.sub_category_block a:hover {
  box-shadow: 0 0 10px #F85C70;
}

.sub_category_block a {
  background: rgba(230, 146, 167, 0.61);
} 

.delete_button {
  margin-bottom: 1rem;
}

.listing_card {
  height: 21rem !important;
}

.info_page {
  min-height: 63vh;
}

.info_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.min_section {
  min-height: 18.75rem;
}

.info_title {
  margin-block: 3rem;
  color: rgba(248, 92, 112, 1);
  font-size: 2rem;
  font-weight: bold;
}

.contact_text {
  margin-bottom: 1.5rem;
  border: 0.5px solid rgba(248, 93, 112, 1);
  padding: 1rem;
  height: 8rem;
}

.card_time {
  margin-block: 0.5rem;
  font-size: 0.85rem;
}

.info_description {
  max-width:  68.9375rem;
  margin: auto;
  text-align: justify;
  width: 75%;
  margin-bottom: 4rem;
}
.footer {
  background: rgba(240, 189, 201, 1);
  height: 18.0625rem;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 3rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.footer div {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-right: 7rem;
}

footer p {
  font-size: 1rem;
}

footer a {
  font-size: 0.75rem
}

.footer a:not(last-child) {
  margin-bottom: 1.5rem;
}



@media (max-width: 971px) {
  body {
    overflow-x: hidden;
  }
  
  button:visited {
    color: black;
  }

  .header {
    padding: 0  2.4375rem;
  }

  .menu {
    cursor: pointer;
  }

  .menu div {
    height: 0.1625rem;
    width: 2.1875rem;
    background: rgba(248, 93, 112, 1);
    border-radius: 3px;
  }

  .menu div:not(last-child){
    margin-bottom: 0.375rem;
  }

  .hide_for_desktop {
    display: block;
  }

  .hide_for_mobile {
    display: none;
  }

  .menu_open {
    height: 100vh;
    width: 66%;
    background-color: rgba(240, 189, 201, 1);
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid rgba(248, 93, 112, 1);
  }


  .menu_open_buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 75%;
    align-items: center;
  }

  .menu_buttons a, button:first-child {
    margin-right: 0;
  }

  .menu_open a {
    margin-bottom: 1.25rem;
  }

  .close_button {
    position: relative;
    right: -77px;
    top: -170px;
    cursor: pointer;
  }

  .search_form {
    width: 100%;
  }

  .search_form input{
    width: 100%;
  }

  .signup_header{ 
    font-size: 1.45rem;
  }

  .signup_form {
    width: 70%;
  }

  .search {
    padding-inline: 1rem;
    width: 100%;
    min-width: 100%;
  }

  .no_listings h2 {
    font-size: 0.85rem;
    text-align: center
  }

  .category_buttons {
    display: grid;
    grid-template-columns: 120px 120px;
    justify-content: center;
    padding-inline: 2rem;
  }

  .category_button {
    margin-bottom: 2rem;
  }

  .category_button p {
    text-align: center;
  }

  .listings {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 2rem;
    flex-wrap: nowrap;
  }

  .listing_page {
    padding: 1.5rem;
  }

  .product_filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-block: 1rem;
    padding-inline: 2rem;
  }

  .listing_page img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .no_results {
    align-self: start;
    text-align: center;
  }


  .listing_page h1, h3 {
    align-self: start;
    margin-block: 0.5rem
  }

  .listings_container {
    min-height: 0;
    justify-content: center;
    padding: 0;
    flex-direction: column;
  }

  
  .price_filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .price_filter select {
    width: 12rem;
  }

  .price_filter input {
    width: 12rem;
  }
  
  .budget input {
    width: 5.8rem;
  }
  .price_filter button{
    padding: 0.75rem 0.4rem;
  }

  .show {
    display: block;
    display: flex;
  }

  .hide {
    display: none;
  }

  .no_block h3 {
    padding: 0 2rem;
  }


  .no_block button {
    margin-block: 1rem 1.5rem;
    width: 13.5rem;
  }



  .no_block h3 {
    margin-bottom: 3rem;
  }


  .upload {
    width: 80%;
    margin: auto;
  }


  .upload_button {
    border: none;
    margin-block: 3rem;
  }

  /* .listing_card .image_box {
    width: 90%;
  } */

  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    height: auto;
    padding: 2rem 2rem;
    position: relative;
    z-index: -1;
    gap: 2rem;
  }

  .footer div {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .footer p {
    font-size: 0.85rem;
  }
}

@media (min-width: 601px){
  .login_block{
    margin-bottom: 11rem;
  }
}